@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

.static-pages-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.static-pages-main-section {
  width: 50%;
  margin: 3% 10%;
}

.mail-link {
  -webkit-text-decoration: underline blue  !important;
          text-decoration: underline blue  !important;
  color: blue !important;
}

.static-pages-update-info {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-align: center;
  color: var(--primaryWebsiteTheme);
}

.static-pages-heading {
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;
  color: var(--staticPagesHeading);
}

.static-pages-heading-body {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin-bottom: 70px;
}

.static-pages-heading-body a {
  color: var(--staticPagesLink);
}

.static-pages-main-heading-body {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.static-pages-main-sub-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.static-pages-section-header {
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
  color: var(--headingColor);
  margin-bottom: 20px;
}

.p-link {
  text-decoration: none;
}

a,
a:-webkit-any-link {
  text-decoration: none !important;
}

.static-pages-def-body {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.static-pages-def-body a {
  color: var(--staticPagesLink);
}

.purpose-scope-root {
  margin-bottom: 40px;
}

.collect-data-root>.static-pages-def-body {
  margin-bottom: 40px;
}

.collect-data-root .privacy-info-body .static-pages-sub-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 10px;
  color: var(--headingColor);
}

.collect-data-root>.privacy-info-body {
  margin-bottom: 25px;
}

.margin-20 {
  margin-bottom: 20px;
}

.margin-left-28 {
  margin-left: 28px !important;
}

.text-left {
  text-align: left !important;
}

.types-of-information {
  margin-top: 90px;
  margin-bottom: 50px;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.static-pages-sub-heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.static-pages-tableWrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--staticPagesTableBorder);
  align-items: center;
}

.static-pages-tableHeading {
  flex: 1 1;
  text-align: center;
  padding: 35px;

  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.static-pages-tableBody {
  flex: 1 1;
  text-align: left;
  padding: 20px;
  border-left: 1px solid var(--staticPagesTableBorder);

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.static-pages-transactionDataTable {
  display: flex;
  flex-direction: column;
}

.static-pages-transactionDataTable>div {
  margin-left: 0px;
}

.cookie-policy {
  color: #4c2c4e;
  max-width: 850px;
  margin: 0 auto;
  padding: 20px;
}

.cookie-policy-intro {
  padding: 60px 0;
}

.cookie-title {
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  color: #001234;
  margin-bottom: 20px;

}


.last-modified-cp {
  text-align: center;
  font-size: 16px;
  line-height: 19.2px;
  letter-spacing: 1px;
  color: #002E6E;
  margin-bottom: 10px;
  font-weight: 600;
}

.intro-text-cp {
  text-align: center;

}

.intro-text-cp,
.section-content-cp {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #475467;
  font-weight: 400;

}

.section-content-cp {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.section-title-cp {
  font-size: 30px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #101828;
  line-height: 38px;
}

.cookie-purpose-list,
.cookie-types-list {
  list-style-type: decimal;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 0px !important;
}

.cookie-purpose-list li,
.cookie-types-list li {
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.cookie-category {
  margin-top: 20px;
  color: #101828;
}

.cookie-category span {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #101828;
}

.cookie-category p {
  margin-bottom: 25px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  color: #475467;
}

.cookie-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.cookie-table th,
.cookie-table td {
  border: 1px solid #4c2c4e;
  padding: 8px;
  text-align: left;
  font-size: 0.9em;
  text-align: center;
}

.cookie-table th {
  font-weight: 600;
  font-size: 16px;
  color: #475467;
  min-width: 145px;
  max-width: 145px;
  text-align: center;
}

.cookie-table td {
  max-width: 145px;
  min-width: 145px;
  white-space: wrap;
  color: #475467;
  word-wrap: break-word;
  white-space: normal;
}

.cookie-table tr,
thead,
th,
td {
  border: 1px solid #61C0FF !important;
  text-align: center;
}

.cookie-mb-table td,
th {
  max-width: 78px !important;
  min-width: 78px !important;
}

.cookie-table-mb1 td,
th {
  max-width: 78px !important;
  min-width: 78px !important;
}

.cookie-table-mb1 td,
th {
  max-width: 78px !important;
  min-width: 78px !important;
}

.cookie-mb-table .head {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.unclassified-text {
  font-size: 18px;
  line-height: 28px;
  color: #475467;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 35px;
}

.profile-save-btn {
  background-color: #002e6e;
  border: none;
  padding: 8px 28px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  color: #fff;
}
.profile-cancel-btn{
  padding: 8px 28px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #002E6E;
  color: #002E6E;
  background-color: #fff;
  margin-right: 15px;
}
.text-align-left{
  text-align: left;
}
.font-size-20{
  font-size: 18px !important;
}
.font-italic{
  font-style: italic !important;
}
.m-lr-3{
  margin-left: 5px !important;
  margin-right: 5px !important;
}
@media (max-width: 576px) {
  .cookie-title {
    margin-top: 10px;
    font-size: 32px;
  }
}

@media (max-width: 1026px) {
  .static-pages-main-section {
    width: 55%;
  }
}

@media (max-width: 769px) {
  .static-pages-main-section {
    width: 65%;
  }

  .static-pages-tableWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .static-pages-tableBody {
    border: none;
    border-top: 1px solid var(--staticPagesTableBorder);
  }
}

@media (max-width: 426px) {
  .static-pages-heading {
    font-size: 32px;
  }

  .static-pages-tableWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .static-pages-tableBody {
    border: none;
    border-top: 1px solid var(--staticPagesTableBorder);
  }
}
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: 0.2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}
.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}
.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: env(safe-area-inset-left);
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}
.Toastify__toast {
  --y: 0;
  position: relative;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: var(--toastify-toast-bd-radius);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
  overflow: hidden;
}
.Toastify__toast--stacked {
  position: absolute;
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform 0.3s;
}
.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity 0.1s;
}
.Toastify__toast--stacked[data-collapsed=false] {
  overflow: visible;
}
.Toastify__toast--stacked[data-collapsed=true]:not(:last-child) > * {
  opacity: 0;
}
.Toastify__toast--stacked:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--g) * 1px);
  bottom: 100%;
}
.Toastify__toast--stacked[data-pos=top] {
  top: 0;
}
.Toastify__toast--stacked[data-pos=bot] {
  bottom: 0;
}
.Toastify__toast--stacked[data-pos=bot].Toastify__toast--stacked:before {
  transform-origin: top;
}
.Toastify__toast--stacked[data-pos=top].Toastify__toast--stacked:before {
  transform-origin: bottom;
}
.Toastify__toast--stacked:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  transform: scaleY(3);
  z-index: -1;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.5s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
  z-index: 1;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
}
.Toastify__progress-bar--wrp[data-hidden=true] {
  opacity: 0;
}
.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y) - 10px), 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y) + 20px), 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }
  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, var(--y), 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
.tippy-box[data-animation=fade][data-state=hidden]{opacity:0}[data-tippy-root]{max-width:calc(100vw - 10px)}.tippy-box{position:relative;background-color:#333;color:#fff;border-radius:4px;font-size:14px;line-height:1.4;white-space:normal;outline:0;transition-property:transform,visibility,opacity}.tippy-box[data-placement^=top]>.tippy-arrow{bottom:0}.tippy-box[data-placement^=top]>.tippy-arrow:before{bottom:-7px;left:0;border-width:8px 8px 0;border-top-color:initial;transform-origin:center top}.tippy-box[data-placement^=bottom]>.tippy-arrow{top:0}.tippy-box[data-placement^=bottom]>.tippy-arrow:before{top:-7px;left:0;border-width:0 8px 8px;border-bottom-color:initial;transform-origin:center bottom}.tippy-box[data-placement^=left]>.tippy-arrow{right:0}.tippy-box[data-placement^=left]>.tippy-arrow:before{border-width:8px 0 8px 8px;border-left-color:initial;right:-7px;transform-origin:center left}.tippy-box[data-placement^=right]>.tippy-arrow{left:0}.tippy-box[data-placement^=right]>.tippy-arrow:before{left:-7px;border-width:8px 8px 8px 0;border-right-color:initial;transform-origin:center right}.tippy-box[data-inertia][data-state=visible]{transition-timing-function:cubic-bezier(.54,1.5,.38,1.11)}.tippy-arrow{width:16px;height:16px;color:#333}.tippy-arrow:before{content:"";position:absolute;border-color:transparent;border-style:solid}.tippy-content{position:relative;padding:5px 9px;z-index:1}
/* Color Classes */
.text-primary {
    color: #001234 !important;
}

.text-secondary {
    color: #424242 !important;
}

.text-tertiary {
    color: #001a47 !important;
}

.text-paragraph {
    color: #292929 !important;
}

.text-pricing {
    color: #525252 !important;
}

.text-section {
    color: #101828 !important;
}
.text-white{
    color: #fff !important;
}

/* Font Size Classes */
.font-xs {
    font-size: 14px !important;
}

.font-sm {
    font-size: 16px !important;
}

.font-md {
    font-size: 20px !important;
}

.font-lg {
    font-size: 24px !important;
}

.font-xl {
    font-size: 32px !important;
}

.font-xxl {
    font-size: 48px !important;
}

.font-xxxl {
    font-size: 64px !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-figtree {
    font-family: 'Figtree' !important;
}
/* @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;800&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

:root {
	--primary-color: #002e6e;
	--text-color: #001a47;
}

/************ DOCUMENT STYLES ****************/
html {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Prompt", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	letter-spacing: 1px;
	overflow-x: hidden;
	background-color: #fff;
	--scroll-behavior: smooth !important;
	scroll-behavior: smooth !important;
}

body {
	height: 100%;
	font-family: "Prompt", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	background-color: #fff;
	margin: 0;
}

/* for Description going out of Box*/
li p {
	word-break: break-word;
	white-space: normal;
}

p {
	word-break: break-word;
	white-space: normal;
}

#__next {
	height: 100%;
}

button:focus,
select:focus,
textarea:focus {
	/* box-shadow: 0px 0px 0px 2px rgba(251, 88, 80, 0.2) !important; */
}

/************ CARD STYLES ****************/
.card {
	box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
	border-radius: 30px;
}

/************ TOASTER STYLES ****************/
.Toastify__toast--error {
	background: rgba(234, 76, 45, 1) !important;
	border: 2px solid rgba(234, 78, 45, 1) !important;
	-webkit-backdrop-filter: blur(100px) !important;
	        backdrop-filter: blur(100px) !important;
	border-radius: 20px !important;
}

/* .Toastify__toast--error::after {
    content: url('../toast/close.svg');
    position: absolute;
    left: 265px;
    top: 22%;
} */

.Toastify__toast--error::before {
	content: url(/_next/static/media/error_fallback.511e3bcf.svg);
	position: relative;
	z-index: 100000;
	left: 0px;
	top: 6px;
}

.Toastify__toast--success {
	background: rgba(56, 179, 89, 1) !important;
	border: 2px solid rgba(56, 179, 89, 1) !important;
	-webkit-backdrop-filter: blur(100px) !important;
	        backdrop-filter: blur(100px) !important;
	border-radius: 20px !important;
}

.Toastify__toast--success::before {
	content: url(/_next/static/media/success_fallback.dfb5baf5.svg);
	position: relative;
	z-index: 100000;
	left: 12px;
	top: 6px;
}

/* .Toastify__toast--success::after {
    content: url('../toast/close.svg');
    position: absolute;
    left: 265px;
} */

.accordion-button:not(.collapsed) {
	color: #363a3f !important;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
	.Toastify__toast--success {
		background: rgba(56, 179, 89, 0.7) !important;
		border: 2px solid rgba(56, 179, 89, 0.3) !important;
	}

	.Toastify__toast--error {
		background: rgba(234, 76, 45, 0.7) !important;
		border: 2px solid rgba(234, 78, 45, 0.3) !important;
		-webkit-backdrop-filter: blur(100px) !important;
		        backdrop-filter: blur(100px) !important;
		border-radius: 20px !important;
	}

	.Toastify__toast--success::before {
		content: url(/_next/static/media/success.ac058728.svg);
	}

	.Toastify__toast--error::before {
		content: url(/_next/static/media/error.3a15951e.svg);
	}
}

.Toastify__toast-body {
	font-size: 16px;
	width: 100%;
	font-weight: 400;
	margin-left: 25px !important;
}

.Toastify__toast > button > svg {
	display: none;
}

/************ TOP NAV BAR STYLE ****************/
.top-nav-bar {
	background-color: #232f3e;
	padding: 10px 0;
	z-index: 10 !important;
}

.hamburger-menu {
	width: 50px;
	font-size: 25px;
	cursor: pointer;
	z-index: 11;
	font-weight: bold !important;
}

.hamburger-menu::before {
	padding-top: 1px;
}

.nav-logo {
	height: 45px;
}

/* XS screens */
@media only screen and (max-width: 480px) {
	.nav-logo {
		padding-left: 0px;
	}
}

.nav-logo-signedout {
	height: 55px;
	padding-left: 0px;
}

/* SM screens */
@media only screen and (min-width: 480px) and (max-width: 768px) {
	.nav-logo {
		padding-left: 0px;
	}
}

/************ SIDE BAR STYLES ****************/
.bm-menu-wrap {
	z-index: 10 !important;
	background-color: #232f3e;
	height: calc(100% - 75px) !important;
	top: 75px;
}

.bm-menu .sidebar-intersect {
	background: url("/img/intersect.svg") left top no-repeat;
	background-size: cover;
	background-color: transparent;
	width: 20px;
	height: 20px;
	left: 80px;
	top: -6px;
}

.bm-menu .bm-item {
	outline: none;
}

.bm-menu .menu-items a {
	text-decoration: none;
}

.bm-menu .menu-items .menu-item {
	margin: 0 5px;
	transition: all 0.5s ease-out;
}

.bm-menu .menu-items .active {
	background: #fff;
	color: #000 !important;
	border-radius: 30px;
	padding: 30px 0 !important;
	margin: 0 8px;
	transition: all 0.5s ease-in;
}

.bm-menu .menu-icon {
	font-size: 25px;
}

.bm-menu .menu-name {
	font-size: 10px;
}

.bm-menu .help {
	bottom: 0;
}

.bm-menu .help .menu-item {
	border-radius: 30px 30px 0 0;
	background: rgba(255, 255, 255, 0.2);
	margin: 0 10px;
}

/************ LOGGED-IN CONTAINER STYLES ****************/
.content-container {
	position: relative;
	height: 100%;
	background: #eee;
}

.signedin {
	min-height: 100%;
	height: auto;
}

#email-notification-button:disabled {
	background: #f39d58;
}

/* XS screens */
@media only screen and (max-width: 480px) {
	.signedin {
		width: 100%;
		left: 0;
	}
}

/* SM screens */
@media only screen and (min-width: 480px) and (max-width: 768px) {
	.signedin {
		width: 100%;
		left: 0;
	}
}

/* MD screens */
@media only screen and (min-width: 768px) {
	.signedin {
		width: calc(100% - 80px);
		left: 80px;
	}
}

.signedout {
	min-height: 100%;
	height: auto;
	width: 100%;
	left: 0;
}

/************ VERTICAL CENTERING CLASSES ****************/
.relative-center {
	top: 50%;
	position: relative;
	transform: translateY(-50%);
}

.vertical-center {
	top: 50%;
	transform: translateY(-50%);
}

/************ MODAL WINDOW STYLES ****************/
.ReactModal__Overlay {
	background-color: rgba(0, 0, 0, 0.75) !important;
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	opacity: 0;
	transform: translateX(-100px);
	transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
	z-index: 2000 !important;
	opacity: 1;
	transform: translateX(0px);
}

.react-modal-side {
	top: 0;
	left: 65%;
	width: 35%;
	height: 100%;
	background: #232f3e;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
	border-radius: 0px;
	position: relative;
	color: #fff;
	padding: 20px;
	overflow: auto;
}

.react-modal {
	width: 40%;
	display: inline-block;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	position: relative;
	overflow: auto;
	max-height: calc(100% - 75px);
}

.payment-check-modal {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.timeout {
	width: 50%;
	overflow: hidden;
}

/* XS screens */
@media only screen and (max-width: 480px) {
	.react-modal {
		width: 80% !important;
	}

	.react-modal-side {
		width: 65% !important;
		left: 35% !important;
	}
}

/* SM screens */
@media only screen and (min-width: 480px) and (max-width: 768px) {
	.react-modal {
		width: 80% !important;
	}

	.react-modal-side {
		width: 65% !important;
		left: 35% !important;
	}
}

/* MD screens */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.react-modal {
		width: 60% !important;
	}
}

/* lg screens */
@media only screen and (min-width: 1200px) {
	.timeout {
		width: 540px;
		overflow: hidden;
	}
}

.react-modal:focus,
.react-modal-side:focus {
	border: none;
	outline: none;
}

.react-modal .react-modal-title .react-modal-side .react-modal-side-title {
	font-weight: bold;
	font-size: 24px;
}

.react-modal .react-modal-close .react-modal-side .react-modal-side-close {
	font-size: 24px;
	font-weight: bold;
}

/************ USER MENU & DROP DOWN STYLES ****************/
.dropdown-menu {
	font-size: 16px;
	border-radius: 10px;
	border: none;
	margin-top: 25px !important;
	z-index: 1021;
}

.dropdown-menu .user-menu-item {
	display: block;
	padding: 15px 24px;
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	overflow: hidden;
}

.dropdown-menu .user-menu-item:hover {
	background: #232f3e;
}

.dropdown-menu div:first-child .user-menu-item:hover {
	border-radius: 10px;
}

.dropdown-menu div:last-child .user-menu-item:hover {
	border-radius: 0 0 10px 10px;
}

.dropdown-menu-position {
	transform: translate3d(-66px, 18px, 0px) !important;
}

.dropdown-menu-arrow-wrapper {
	position: relative;
	width: 0;
	height: 0;
	top: 2px;
}

.dropdown-item {
	cursor: pointer;
}

.dropdown-item:focus,
.dropdown-item:hover {
	background: #fb5850;
	color: white;
}

.menu-items-wrapper {
	margin-top: -18px;
}

.submenu-menu-arrow-wrapper {
	left: 80%;
}

.dropdown-menu-arrow {
	top: -39px;
	left: 90px;
	width: 0;
	height: 0;
	position: absolute;
	display: inline;
}

.dropdown-menu-arrow:after {
	content: "";
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-width: 15px 12px;
	border-style: solid;
	border-color: transparent;
	z-index: 1001;
	border-bottom-color: #ffffff;
}

.app-dropdown-menu-arrow-wrapper {
	left: -26px;
}

/************ POLICY PAGES ****************/
.policy-main-section {
	color: black;
	padding: 5% 10%;
	letter-spacing: normal;
}

.policy-heading {
	color: black;
	text-align: center;
	margin-bottom: 50px;
}

.policy-heading {
	font-weight: 700;
	color: #572148;
}

.cookie-section,
.privacy-section {
	text-align: left;
	margin-top: 4%;
	color: black;
}

.cookie-section-header h3,
.privacy-section-header h3 {
	margin-bottom: 20px;
	font-size: 24px;
	font-weight: 700;
	color: #572148;
}

.cookie-set-body span,
.privacy-section span,
.privacy-section li::marker,
.privacy-info-body li::marker {
	font-weight: 700;
	color: #572148;
}

.cookie-thirdParty-body li {
	margin-bottom: 2%;
}

.cookie-thirdParty-body span {
	display: block;
	margin-top: 5px;
}

.rdt_TableCol_Sortable:hover,
.rdt_TableCol_Sortable:focus {
	color: white !important;
}

/************ TEL INPUT / PHONE NUMBER INPUT STYLES ****************/

.intl-tel-input span,
.country-name {
	color: black !important;
}

.intl-tel-input .arrow {
	pointer-events: none;
}

.intl-tel-input.allow-dropdown .selected-flag {
	overflow-x: hidden;
	display: flex !important;
	justify-content: flex-start;
	padding-left: 6px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 .selected-flag .selected-dial-code {
	padding-left: 5px;
	padding-right: 3px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-5 .selected-flag .selected-dial-code {
	padding-left: 5.8px;
	padding-right: 3.8px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 .selected-flag .selected-dial-code {
	padding-right: 2px;
	padding-left: 8px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-2 .selected-flag .selected-dial-code {
	padding-right: 2px;
	padding-left: 11px;
}

.intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-4 input {
	/* // padding-left: 92px; */
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
	pointer-events: none;
	height: 100%;
	display: flex !important;
	align-items: center;
	background: white;
	position: absolute;
	left: 43px;
	color: grey;
}

/************ Slick Slider CUSTOM STYLES ****************/
.slick-track {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.slick-prev:before,
.slick-next:before {
	font-family: "slick";
	color: transparent !important;
}

/************ TIPPY CUSTOM STYLES ****************/
.custom-tippy {
	background-color: #2b394a !important;
	border-radius: 10px !important;
	padding: 10px !important;
	color: #fff !important;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
	border-top-color: #2b394a !important;
	/* set your color here and use the !important property */
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
	border-bottom-color: #2b394a !important;
	/* set your color here and use the !important property */
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
	border-right-color: #2b394a !important;
	/* set your color here and use the !important property */
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
	border-left-color: #2b394a !important;
	/* set your color here and use the !important property */
}

/************ FLAG DROPDOWN ****************/

.selected-flag:focus {
	outline: none;
	border: none;
}

/************ COOKIE NOTIFICAION ****************/
.cookie-not-con {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 2147483645;
	box-sizing: border-box;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px #00000026;
}

.cookie-not-con p {
	margin-bottom: 0px;
}

p {
	margin-bottom: 0px;
}

/************ NEW LINE ISSUE ********************/
.line-clamp p {
	margin-bottom: 0;
}

.z-10000 {
	z-index: 10000 !important;
}

.phone-input-intel .intl-tel-input {
	width: 100% !important;
	border-radius: 224px !important;
}
.phone-input-intel .intl-tel-input.allow-dropdown.separate-dial-code.iti-sdc-3 input[type="tel"] {
	height: 35px !important;
	border-radius: 2px !important;
}

:root {
	--primaryRegistrationTheme: #002e6e;
}

*:disabled {
	cursor: not-allowed;
}

.orange {
	color: #fb5850;
}

/************ INPUT STYLES ****************/
input.form-control,
select.form-control {
	background: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 5px;
	padding: 10px;
}

textarea {
	border: 1px solid #cccccc !important;
	background: #ffffff;
	border-radius: 7px !important;
}

input.form-control:focus {
	box-shadow: 0px 0px 0px 2px rgba(251, 88, 80, 0.2) !important;
}

/************ REACT TEL INPUT STYLES ****************/
.iti-flag {
	background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti-flag {
		background-image: url("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/img/flags.png");
	}
}

/**************** CUSTOM RADIO BUTTON **************************/
.custom-radio-label,
.custom-radio-label-success,
.custom-radio-label-error {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.custom-radio-label-text {
	padding-left: 35px !important;
}

/* Hide the browser's default radio button */
.custom-radio-label input,
.custom-radio-label-success input,
.custom-radio-label-error input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Unchecked Radio Button Style */
.theme-radio {
	position: absolute;
	height: 25px;
	width: 25px;
	background-image: url("/img/controls/custom-radio-unchecked.svg");
	background-size: cover;
}

/* Checked Radio Button Style */
.custom-radio-label input:checked ~ .theme-radio {
	background-image: url("/img/controls/custom-radio-checked.svg");
	background-size: cover;
}

.custom-radio-label-success input:checked ~ .theme-radio {
	background-image: url("/img/controls/custom-radio-checked-success.svg");
	background-size: cover;
}

.custom-radio-label-error input:checked ~ .theme-radio {
	background-image: url("/img/controls/custom-radio-checked-error.svg");
	background-size: cover;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.theme-radio:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio-label input:checked ~ .theme-radio:after,
.custom-radio-label-success input:checked ~ .theme-radio:after,
.custom-radio-label-error input:checked ~ .theme-radio:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio-label .theme-radio:after,
.custom-radio-label-success .theme-radio:after,
.custom-radio-label-error .theme-radio:after {
	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
}

/**************** CUSTOM CHECK BOX **************************/
input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] + label:before {
	background: #fafbfd;
	border: 1px solid #b5d6ff;
	border-radius: 3px;
	content: "\00a0";
	display: inline-block;
	font: 16px/1em Amazon Ember;
	height: 18px;
	margin: 0.1em 0.6em 0 0;
	padding: 0;
	vertical-align: top;
	width: 18px;
}

input[type="checkbox"]:checked + label:before {
	background: #fb5850;
	color: #fff;
	content: "\2713";
	text-align: center;
	border: 1px solid #fb5850;
}
.manage-profile-form input[type="checkbox"]:checked + label:before {
	background: #002e6e;
	color: #fff;
	content: "\2713";
	text-align: center;
	border: 0.77px solid #d0d5dd;
}

input[type="checkbox"]:focus + label::before {
	outline: rgb(59, 153, 252) auto 5px;
}

/************ BUTTON STYLES ****************/
button.btn {
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	border: none;
	font-size: 16px;
	font-weight: 700;
	padding: 10px 30px;
}

button.btn-theme:hover {
	color: #fff;
}
/****** Galaxy Fold ********/
@media only screen and (max-width: 281px) {
	button.btn {
		padding: 10px 15px;
	}
}

button.btn-theme {
	background: var(--primaryRegistrationTheme);
	color: #fff;
}
button.outline-btn {
	border: 1px solid var(--primaryRegistrationTheme);
	background: transparent;
	color: var(--primaryRegistrationTheme);
	border-radius: 5px;
	font-size: 16px;
	font-weight: 700;
	padding: 10px 30px;
	transition: 0.2s all;
}

button:disabled {
	cursor: not-allowed !important;
	pointer-events: all !important;
}

button.outline-btn:hover {
	background: white;
	color: var(--primaryRegistrationTheme);
}

.whatsapp-button {
	width: 346px;
	margin-top: 30px;
	background: #20b038;
	color: #ffffff;
	margin-bottom: 40px;
}

.no-box-shadow {
	box-shadow: none !important;
}

/************ PASSWORD MASK STYLES ****************/
.password-show {
	background: none !important;
	font-size: 22px;
	top: 50% !important;
	transform: translateY(-50%);
	margin-top: 0 !important;
	color: #5495f9 !important;
}

/************ HORIZONTAL RULES STYLES ****************/
hr {
	color: #dddddd;
}

/************ MAKE THE CUSOR POINTER ****************/
.pointer {
	cursor: pointer;
}

/************ REACT SELECT BOX ****************/
.react-select-padding__value-container {
	padding: 2px 5px !important;
}

.react-select-padding__control {
	background: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 5px !important;
}

.react-select-padding__control:focus,
.react-select-padding__control--is-focused {
	box-shadow: 0px 0px 0px 2px rgba(251, 88, 80, 0.2) !important;
}

/*************** Partner ********************/

.booth-contact-box {
	width: 300px;
	font-family: "Figtree";
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
}

/*************** ANIMATIONS ******************/

@keyframes slideUp {
	0% {
		transform: translateY(100px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes slideDown {
	0% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(70px);
		visibility: hidden;
	}
}

/*************** BUTTON ******************/

.btn-demo {
	background: #572148 !important;
}

.btn-demo:hover {
	color: #572148;
	background: #fb5850 !important;
}

.btn:focus {
	box-shadow: 0 0 0 0.2rem rgb(33 43 87 / 25%) !important;
}
.btn-header:hover {
	color: white;
	background: var(--primaryRegistrationTheme);
}

a:has(.btn-header):hover {
	color: #572148;
}

.btn-header {
	background: var(--primaryRegistrationTheme);
	color: #fff;
	font-size: 18px;
	padding: 15px 25px;
	box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	white-space: nowrap;
}
.btn-register {
	background: #572148;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	padding: 5px 22px;
	box-shadow: 3px 3px 25px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	border: none;
}

/***************CAROUSEL STYLES*****************/
.rec-carousel-item {
	display: flex;
	flex-grow: 1;
}

.rec-arrow-left,
.rec-arrow-right {
	background: none !important;
	box-shadow: none !important;
	font-size: 20px !important;
	color: #572148 !important;
}

.rec-arrow-left:disabled,
.rec-arrow-right:disabled {
	color: #999 !important;
}

.rec-dot {
	width: 8px !important;
	height: 8px !important;
	background-color: #c5e2ff !important;
	box-shadow: none !important;
}

.rec-dot_active {
	background-color: #002e6e !important;
}

.usecases-card {
	background: #23313e;
	box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.25);
	border: 4px solid #ffffff;
	border-radius: 0.5px;
	margin: 20px;
}

@media only screen and (max-width: 766px) {
	.whatsapp-button {
		width: 336px;
	}
}

/********************* THIS IS FOR ALL IMAGES UPLOADED IN EDITOR *******************************/
.image-reference {
	overflow: auto;
}


/* Color Classes3 */
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

html {
	font-family: 'Figtree' !important;
}
body {
	font-family: 'Figtree' !important;
}
.text-primary {
    color: #001234 !important;
}

.text-secondary {
    color: #424242 !important;
}

.text-tertiary {
    color: #001a47 !important;
}

.text-paragraph {
    color: #292929 !important;
}

.text-pricing {
    color: #525252 !important;

}

/* Font Size Classes */
.font-xs {
    font-size: 14px;
}

.font-sm {
    font-size: 16px;
}
.font-sm-2 {
    font-size: 18px;
}
.font-md {
    font-size: 20px;
}

.font-lg {
    font-size: 24px;
}

.font-xl {
    font-size: 32px;
}

.font-xxl {
    font-size: 48px;
}

.font-xxxl {
    font-size: 64px;
}
/* font-family */
.figtree-family{
    font-family: 'Figtree' !important;
}
.font-weight-light {
    font-weight: 300; 
  }
  
  .fw-normal {
    font-weight: 400; 
  }
  
  .fw-medium {
    font-weight: 500; 
  }
  
  .fw-semibold {
    font-weight: 600; 
  }
  
  .fw-bold {
    font-weight: 700; 
  }
  
  .fw-extra-bold {
    font-weight: 800; 
  }
  
  .fw-black {
    font-weight: 900; 
  }
  
  
